<template>
  <!-- 隐私协议 -->
  <div id="Privacy">
    <div class="page1">
      <div class="tit">{{ "小植物隐私协议" }}</div>
      <div class="img">
        <van-image
            width="42"
            :src="require('../../assets/record/record_page1_logo.png')"
            class="logo"/>
      </div>
    </div>
    <div class="box">
      <p>小植物隐私协议</p>
      <p>更新日期：2022年9月26日</p>
      <p>生效日期：2022年9月26日</p>
      <p>福建自然生长科技有限公司及其关联方（简称“我们”）作为小植物的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。<br/>
        我们制定本“隐私政策”并特别提示：</p>
      <p>希望您在使用小植物及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</p>
      <p>本隐私政策将帮助您了解：<br/>
        •我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。<br/>
        •当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明哪些是必要信息。<br/>
        •如果您未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了帐号，我们会根据帐号信息实现信息推送。<br/>
        •存储权限、相册权限等重要权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。
      </p>
      <p>小植物权限使用与使用情况说明</p>
      <p>
        •本隐私政策适用于您通过小植物应用程序、小植物官方网站、供第三方网站和应用程序使用的小植物软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。</p>
      <p>1.我们如何收集和使用个人信息</p>
      <p>
        我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
      <p>1.1注册、登录</p>
      <p>1.1注册、登录帐号</p>
      <p>
        a.当您注册、登录小植物及相关服务时，您可以通过手机号创建帐号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善您的信息。<br/>
        b.您也可以使用第三方帐号登录并使用小植物，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与小植物帐号绑定，使您可以直接登录并使用本产品和相关服务。在使用第三方账号进行登录时，可能需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用，不会收集您的隐私信息。<br/>
        c.登录第三方帐号：当您使用小植物帐号登录第三方帐号时，经过您的同意，我们可能会共享昵称、头像以及您授权的其他信息。<br/>
        d.基于我们与通信运营商的合作，当您使用小植物“一键登录”功能时，经过您的明示同意，运营商会将您的手机号码发送给我们，便于我们为您提供快捷的登录服务。手机号码属于个人敏感信息，如果拒绝提供将无法使用“一键登录”方式注册登录小植物，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。<br/>
        e.我们会收集登录时所在省市的大概位置信息，不会记录精确信息，用于统计注册登录用户的来源地。</p>
      <p>1.2播放浏览</p>
      <p>a.为保障推荐内容的质量并向您推荐可能感兴趣的视频及相关信息，小植物可能会收集相关必要的日志信息。<br/>
        b.上述日志信息包括：您操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享您主动提供的信息：反馈、发布、点赞（喜欢）、评论地理位置信息：含通过通过网络位置信息（例如基站、IP及WLAN）获取的大致地理位置信息<br/>
        c.我们会基于大致地理位置信息对应的城市及/或区县向您展示、推荐您所在地区（城市、区县等）的本地相关信息。</p>
      <p>1.3发布与互动</p>
      <p>1.3.1信息发布</p>
      <p>a.您发布音视频、图片等内容或进行评论时，我们将收集您发布的信息，并展示您的昵称、头像、发布内容和信息。<br/>
        b.您使用发布图片、音视频功能时，我们会请求您授权相机、照片、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用小植物的其他功能。<br/>
        c.您使用“同城”及相关服务或发布信息时，我们会请求您授权精确地理位置权限，并收集与本服务相关的位置信息，以帮助您在发布信息或互动时显示位置。您如果拒绝授权提供精确地理位置信息，我们会通过您的IP地址对应到您所在的城市及/或区县。如果您希望在作品中添加地理位置信息，您可以通过授权精确地理位置权限或主动选择的方式（搜索或平台推荐选项等）进行添加。<br/>
        d.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分信息（如：在评论、留言、发布图文、音视频中涉及到与您相关的信息）<br/>
        e.我们非常重视用户的使用体验，为了提升音视频的上传速度、丰富发布功能和优化体验，当您发布音视频时，在点击“发布”确认上传之前，我们可能会将该音视频临时加载至服务器。如果您取消或因其他原因未成功上传，我们会立即删除相关音视频。我们尊重您的选择权，如果您想关闭预加载功能，可以在【我】-【设置】-【通用设置】-【提前上传作品，减少等待时间】进行关闭。
      </p>
      <p>1.3.2互动交流</p>
      <p>a.当您关注帐号、进行浏览、评论、关注（收藏）、喜欢（点赞）或分享时，我们会收集您关注的帐号和前述信息，并展示关注帐号发布内容。<br/>
        b.您可以在小植物中添加或删除朋友，为帮您实现这一功能，我们会创建朋友列表信息。朋友列表是建立与管理朋友关系所必需的必要信息。<br/>
        c.当您选择使用扫码添加朋友时，小植物可能会向您请求摄像头权限，摄像头权限是敏感权限，拒绝提供仅会使您无法通过扫码途径添加朋友，不影响其他功能的使用。
      </p>
      <p>1.3.3消息通知</p>
      <p>
        您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途；此外，我们也可能会以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息的用途，但请您放心，如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</p>
      <p>1.4搜索</p>
      <p>
        您使用小植物的搜索服务时，我们会收集您的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。</p>
      <p>1.5运营与安全运行</p>
      <p>1.5.1运营与安全保障</p>
      <p>我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。</p>
      <p>1.5.2设备信息与日志信息</p>
      <p>a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的设备信息（IMEI/MAC/Android
        ID/IDFA/OpenUDID/GUID/IP地址/地理位置/SIM
        卡<br/>
        IMSI信息）、设备序列号、设备型号、硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。<br/>
        b.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源、app内部操作行为。<br/>
        c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。
      </p>
      <p>1.6分享与活动参与</p>
      <p>
        在您分享或接收被分享的信息、参加活动等情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。</p>
      <p>1.7统计分析</p>
      <p>
        为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请您放心，我们不会收集您的隐私信息。</p>
      <p>1.8收集、使用个人信息目的变更</p>
      <p>
        请您了解，随着我们业务的发展，可能会对小植物的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。</p>
      <p>1.9依法豁免征得同意收集和使用的个人信息</p>
      <p>请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：<br/>
        a.与国家安全、国防安全直接相关的；<br/>
        b.与公共安全、公共卫生、重大公共利益直接相关的；<br/>
        c.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br/>
        d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        e.所收集的您的个人信息是您自行向社会公众公开的；<br/>
        f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
        g.根据您的要求签订或履行合同所必需的；<br/>
        h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；<br/>
        i.为合法的新闻报道所必需的；<br/>
        j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
        k.法律法规规定的其他情形。</p>
      <p>
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
      <p>2.我们如何使用Cookie等同类技术</p>
      <p>
        Cookie和设备信息标识等同类技术是互联网中普遍使用的技术。当您使用小植物及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集、标识您访问、使用本产品时的信息。我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie和同类技术主要为了实现以下功能或服务：</p>
      <p>2.1保障产品与服务的安全、高效运转</p>
      <p>
        我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</p>
      <p>2.2帮助您获得更轻松的访问体验</p>
      <p>使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。</p>
      <p>2.3为您推荐、展示、推送您可能感兴趣的内容或帐号</p>
      <p>a.我们可能会利用此类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息或功能，并优化您对广告的选择。<br/>
        b.在小植物的分享页中，我们可能会使用Cookie对浏览活动进行记录，用于向您推荐信息和排查崩溃、延迟的相关异常情况以及探索更好的服务方式。
      </p>
      <p>2.4Cookie的清除</p>
      <p>
        大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。</p>
      <p>3.我们如何共享、转让、公开披露个人信息</p>
      <p>3.1共享</p>
      <p>我们不会与小植物提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：<br/>
        1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br/>
        2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br/>
        3、在您主动选择情况下共享：您通过小植物平台购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易及售后服务需求。<br/>
        4、您理解并同意，为了必要/合理的业务顺利开展、满足您的要求、履行我们在相关用户协议或本隐私政策中的义务和形式我们的权利或遵守法律规定等目的，我们可能需要向部分业务合作伙伴共享您的个人信息。但我们承诺仅共享必要的个人信息，如果我们共享您的个人敏感信息或改变个人信息的使用及处理目的，我们将会再次征求您的授权和同意。<br/>
        5、仅为实现本隐私政策中声明的目的，我们可能会与我们的供应商、服务提供商、顾问或代理人共享您的个人信息，以提供更好的客户服务和用户体验。这些供应商、服务提供商、顾问或代理人可能为我们提供技术基础设施服务，分析我们服务的使用方式，衡量广告和服务的有效性，提供客户服务，进行学术研究和调查，或提供法律、财务和技术顾问服务。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br/>
        我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。为了更好运营和改善技术和服务，您同意我们和授权合作伙伴在符合相关法律法规的前提下可将收集的信息用于其他服务和用途。<br/>
        为保障小植物App相关功能的实现，我们使用了第三方服务商以SDK形式提供的能力与服务，在本协议末尾列举了所有服务的授权合作伙伴，并提供了该第三方的隐私政策链接或官网链接，若有疑问，我们建议您阅读该第三方的隐私政策或访问官网了解。
      </p>
      <p>3.1.1共享原则</p>
      <p>
        a.授权同意原则：未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。<br/>
        b.合法正当与最小必要原则：共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。<br/>
        c.安全审慎原则：我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。
      </p>
      <p>3.1.2实现功能或服务的共享信息</p>
      <p>
        a.当您在使用小植物中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为您提供服务时我们会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计并通过算法做特征与偏好分析，形成间接人群画像，用以向您进行推荐、展示或推送您可能感兴趣的信息，或者推送更适合您的特定功能、服务或商业广告。<br/>
        b.登录其他第三方帐号：当您使用小植物帐号登录其他第三方的产品或服务时，或将小植物帐号与其他第三方帐号绑定，经过您的同意，我们会将您的昵称、头像、直接或间接关注、粉丝等通讯关系及其他您授权的信息与前述产品或服务共享。您可以在【设置】-【帐号与安全】-【授权管理】中管理您对其他第三方产品或服务的授权管理，或在【设置】-【帐号与安全】-【第三方帐号绑定】中管理您绑定的第三方帐号。请您注意，在您取消对其他第三方产品或服务的授权后，您可能无法使用已取消授权的小植物帐号再次登录该第三方产品或服务；如需登录，可能需要您再次授权。c.小程序：当您使用小程序时，未经您同意，我们不会向这些开发者、运营者共享您的个人信息。当您使用小程序时，小程序可能会使用您授权的相关系统权限，您可以在小程序中撤回授权。<br/>
        c.为与您使用的终端机型适配消息推送功能，我们可能会通过SDK等技术与终端设备制造商（华为、小米、OPPO、VIVO等）共享手机型号、版本及相关设备信息。
      </p>
      <p>3.1.4实现安全与分析统计的共享信息</p>
      <p>
        a.保障使用安全：我们非常重视帐号、服务及内容安全，为保障您和其他用户的帐号与财产安全，使您和我们的正当合法权益免受不法侵害，我们和关联方或服务提供商可能会共享必要的设备、帐号及日志信息。<br/>
        b.分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别您的个人身份。<br/>
        c.学术研究与科研：为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与科研院所、高校等机构共享去标识化或匿名化的数据。
      </p>
      <p>3.1.5帮助您参加营销推广活动</p>
      <p>
        当您选择参加我们及我们的关联方或第三方举办的有关营销活动时，可能需要您提供姓名、通信地址、联系方式、银行帐号等信息。其中部分信息是个人敏感信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在联合活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。</p>
      <p>3.2转让</p>
      <p>a.我们不会转让您的个人信息给任何其他第三方，除非征得您的明确同意。<br/>
        b.随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。
      </p>
      <p>3.3公开展示<br/>
        a.我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。<br/>
        b.对违规帐号、欺诈行为进行处罚公告时，我们会披露相关帐号的信息。<br/>
        c.当您授权游戏或小程序后，您和朋友的头像、昵称将会在排名及相关功能中展示。<br/>
        d.您公开发布的作品、动态、喜欢（点赞）将会在个人主页中展示。<br/>
        e.您可以在【设置】-【隐私设置】-【私密帐号】中选择是否开启“私密帐号”功能。开启后，未经您的同意，我们不会将您发布的内容和点击“喜欢”的状态在其他软件中展示。只有您批准的用户才能关注您，并能看到您的内容和喜欢（点赞），但现有的粉丝不会受到影响
      </p>
      <p>3.4依法豁免征得同意共享、转让、公开披露的个人信息请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：<br/>
        a.与国家安全、国防安全直接相关的；<br/>
        b.与公共安全、公共卫生、重大公共利益直接相关的；<br/>
        c.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br/>
        d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        e.您自行向社会公众公开的个人信息；<br/>
        f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p>4.我们如何存储个人信息</p>
      <p>4.1存储地点</p>
      <p>
        我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。</p>
      <p>4.2存储期限</p>
      <p>
        我们仅在为提供小植物及服务之目的所必需的期间内保留您的个人信息：您发布的信息、评论、喜欢（点赞）及相关信息，在您未撤回、删除或未注销帐号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
      <p>5.我们如何保护个人信息的安全</p>
      <p>
        a.我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。<br/>
        b.我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。<br/>
        c.我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。<br/>
        d.尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。<br/>
        e.您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。<br/>
        f.我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。<br/>
        g.您一旦离开小植物及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在小植物及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于小植物的链接或引导。
      </p>
      <p>6.管理您的个人信息</p>
      <p>
        我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销帐号、投诉举报以及设置隐私功能的相关权利，以使您有能力保障您的隐私和信息安全。</p>
      <p>6.1自主选择控制个性化推荐信息</p>
      <p>自主订阅所需信息在关注中会根据您主动选择关注的帐号信息进行展示，您可以自主选择关注或取消关注。</p>
      <p>6.2改变或撤回授权范围</p>
      <p>6.2.1改变或撤回授权设置</p>
      <p>你可以在系统设置中的应用管理列表中找到我们的app，然后根据具体需求调整改变或者撤回授权方式</p>
      <p>6.2.2改变或撤回敏感权限设置</p>
      <p>
        a.您可以在设备本身操作系统中关闭精确地理位置信息、通讯录、摄像头、麦克风、相册权限、日历权限，改变同意范围或撤回您的授权。撤回授权后我们将不再收集与这些权限相关信息。</p>
      <p>
        6.2.3改变或撤回授权的信息处理特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</p>
      <p>6.3访问、删除、更正您的个人信息</p>
      <p>
        6.3.1访问个人帐号信息您可以查询、访问您的头像、用户名、简介、性别、生日、地区，您可以在【我】-【编辑资料】进行查询、访问、更正。</p>
      <p>6.3.2查询访问、更正、删除信息</p>
      <p>a.进入【我】-【作品】中访问、删除您发布的音视频信息<br/>
        b.进入【我】-【喜欢】中访问、删除您点赞（喜欢）的信息<br/>
        c.进入【关注】可以访问、查询关注的帐号信息，在关注列表点击相应帐号后的“已关注”按钮（如有）或进入相应关注帐号主页可以选择取消关注该帐号<br/>
        d.进入【我】-【设置】-【帐号与安全】访问小植物号、小植物码等信息、访问更正绑定的手机号、第三方帐号、设置、更正小植物密码、访问实名认证信息、登录设备信息<br/>
        e.出于安全性和身份识别的考虑，您可能无法自主修改注册时提交的某些初始注册信息（如实名认证后信息）；如您确有必要修改该类信息，请您联系我们，我们将尽快审核所涉问题，并由专人验证您的用户身份后及时予以回复。
      </p>
      <p>6.4注销帐号</p>
      <p>
        您可以在【我】-【设置】-【帐号与安全】-【小植物安全中心】进行帐号注销。在您注销帐号前，我们将验证您的个人身份、安全状态、设备信息等。有关注销的流程和内容，请参见《小植物注销须知》。您知悉并理解，注销帐号的行为是不可逆的行为，当您注销帐号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。</p>
      <p>6.5投诉举报</p>
      <p>
        您可以按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以在【我】-【设置】-【反馈与帮助】，进入用户反馈界面与我们联系。我们核查后会在30日内反馈您的投诉与举报。</p>
      <p>6.6访问隐私政策</p>
      <p>a.您可以在注册页面，或者在登录个人帐号【我】-【设置】-【隐私政策】查看本隐私政策的全部内容。<br/>
        b.请您了解，本隐私政策中所述的小植物及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的小植物软件及相关服务为准。
      </p>
      <p>
        6.7停止运营向您告知如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
      <p>7.未成年人条款</p>
      <p>a.若您是未满18周岁的未成年人，在使用小植物及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。<br/>
        b.我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。同时我们建立了严格的未成年人信息收集使用规则，以保护儿童和青少年个人信息安全，您可以通过阅读《儿童/青少年使用须知》了解更具体内容。<br/>
        c.若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
      </p>
      <p>8.隐私政策的修订和通知</p>
      <p>
        a.为了给您提供更好的服务，小植物及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。<br/>
        b.本隐私政策更新后，我们会在小植物发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
      </p>
      <p>
        9.联系我们如果您对个人信息保护问题有投诉、建议、疑问，您可以将问题发送至（smallplants2013@163.com），并在验证您的用户身份后的十五个工作日内回复。</p>
      <p>10.第三方SDK使用清单</p>
      <p>SDK名称：微信SDK<br/>
        公司名称：腾讯科技（深圳）有限公司<br/>
        隐私政策地址：<a
            href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</a><br/>
        使用场景：在用户使用微信登录或者将内容分享到微信时使用<br/>
        收集方式：SDK采集<br/>
        收集信息类型：设备标识信息、mac地址、设备型号、操作系统、登录IP地址</p>
      <p>SDK名称：QQ SDK<br/>
        公司名称：腾讯科技（深圳）有限公司<br/>
        隐私政策地址：<a href="https://privacy.qq.com/policy/tencent-privacypolicy">https://privacy.qq.com/policy/tencent-privacypolicy</a><br/>
        使用场景：在用户使用QQ授权登录、将内容分享到QQ、唤起QQ客服聊天时使用<br/>
        收集方式：SDK采集<br/>
        收集信息类型：设备标识信息、mac地址、设备型号、操作系统、登录IP地址</p>
      <p>SDK名称：新浪微博SDK<br/>
        公司名称：北京微梦创科网络技术有限公司<br/>
        隐私政策地址：<a href="https://m.weibo.cn/c/privacy">https://m.weibo.cn/c/privacy</a><br/>
        使用场景：在用户使用微博授权登录、或将内容分享到微博时使用<br/>
        收集方式：SDK采集<br/>
        收集信息类型：设备标识信息、设备mac地址、设备型号、操作系统、登录IP地址、获取IMSI、获取Android_ID、获取SIM序列号</p>
      <p>SDK名称：友盟SDK<br/>
        公司名称：友盟同欣科技有限公司<br/>
        隐私政策地址：<a
            href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a><br/>
        使用场景：统计分析、推送通知、社会化分享、防作弊、收集app崩溃信息、设备应用性能检测<br/>
        收集方式：SDK采集<br/>
        收集信息：设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/IP地址/地理位置/SIM 卡<br/>
        IMSI信息/设备序列号/设备型号/操作系统/登录IP地址）、app内部操作行为、已安装的应用信息、运行中的进程信息</p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Privacy",
  mounted() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/iPhone/i) == "iphone") {
      //iphone
    } else if (ua.match(/Android/i) == "android") {
      //android
      $("#app").css("font-family", "Microsoft Yahei");
    }
  },
  data() {
    return {};
  },
};
</script>

<style>
#Privacy pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 500;
}

#Privacy .box {
  padding: 10px;
  font-size: 15px;
  white-space: normal;
}

#Privacy .page1 {
  position: relative;
  background-color: #26262a;
  color: #fff;
  overflow: hidden;
  height: 200px;
}

#Privacy .page1 .tit {
  position: absolute;
  top: 41%;
  left: 17px;
  transform: translate(0, -50%);
  font-weight: 900;
  font-size: 30px;
}

#Privacy .page1 .img {
  width: 42px;
  height: 27px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  border: 1px solid #fff;
  line-height: 27px;
  padding: 1px 7px;
  border-radius: 5px;
}

#Privacy .page1 .img .logo {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
</style>
